<template>
  <div class="support">
    <Nav></Nav>
    <section id="header" class="px-4 pt-20 pb-10 mx-auto items-center text-center">
      <h1 class="animate__animated animate__slideInTop max-auto mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
        Support<span class="text-green-500">.</span>
      </h1>
      <p class="animate__animated animate__slideInTop text-base text-gray-700 md:text-lg">
        Reliable support at your fingertips.
      </p>
    </section>

    <div class="text-green-400 text-center text-xl flex flex-col" style="letter-spacing: 2em;">
      <span>•</span>
      <span>•</span>
      <span>•</span>
    </div>

    <section id="form">
       <div class="flex flex-row px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl ">
         <div class="flex flex-col w-1/4 pr-3">
           <div class="shadow-sm hover:shadow-lg rounded-lg border-2 border-gray-300 px-4 py-5 mb-3">
             <h4 class="text-xl font-bold">Our Support Hours</h4>
             <p class="text-gray-700 font-medium py-2">10AM to 5PM <br/><span class="text-sm text-gray-500">(Except Weekends and Public Holidays)</span></p>
           </div>
            <div class="shadow-sm hover:shadow-lg rounded-lg border-2 border-gray-300 px-4 py-5 mb-3">
             <h4 class="text-xl font-bold mb-3">Find us on Facebook</h4>
              <div class="fb-like" data-href="https://www.facebook.com/duitcount" data-width="260" data-layout="button_count" data-action="like" data-size="large" data-share="true"></div>           </div>
         </div>
         <form action="#"
               @submit.prevent="submit"
               data-netlify="true"
               data-netlify-recaptcha="true"
               class="w-3/4" method="POST">
           <input type="hidden" name="form-name" value="support_form" />
           <div class="shadow overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-3">
                  <label for="first_name" class="block font-medium text-gray-700">First name</label>
                  <input :disabled="form_loading"  type="text" name="first_name" id="first_name"
                         v-model="form.first_name"
                         :class="{'border-red-500': $v.form.first_name.$error, 'border-gray-300': !$v.form.first_name.$error}"
                         autocomplete="given-name" class="mt-1 px-3 py-2 border-2 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm rounded-md">
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label for="last_name" class="block font-medium text-gray-700">Last name</label>
                  <input :disabled="form_loading"  type="text" name="last_name" id="last_name"
                         v-model="form.last_name"
                         :class="{'border-red-500': $v.form.last_name.$error, 'border-gray-300': !$v.form.last_name.$error}"
                         autocomplete="family-name" class="mt-1 px-3 py-2 border-2 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm border-gray-300 rounded-md">
                </div>

                <div class="col-span-6 sm:col-span-4">
                  <label for="email_address" class="block font-medium text-gray-700">Email address</label>
                  <input  :disabled="form_loading"  type="text" name="email_address" id="email_address" placeholder="Eg. me@example.com"
                          v-model="form.email"
                          :class="{'border-red-500': $v.form.email.$error, 'border-gray-300': !$v.form.email.$error}"
                          autocomplete="email" class="mt-1 px-3 py-2 border-2 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm border-gray-300 rounded-md">
                  <div class="text-sm font-medium text-gray-500 mt-2">Preferably Your DuitCount Account Email Address</div>
                </div>
                <div class="col-span-6 sm:col-span-4">
                  <label for="message" class="block font-medium text-gray-700">Message</label>
                  <textarea  :disabled="form_loading"
                             v-model="form.message"
                             :class="{'border-red-500': $v.form.message.$error, 'border-gray-300': !$v.form.message.$error}"
                             class="mt-1 px-3 py-2 border-2 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm border-gray-300 rounded-md"></textarea>
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button :disabled="form_loading" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                {{ (form_loading) ? 'Submitting...' : 'Submit' }}
              </button>
            </div>
          </div>
        </form>
       </div>
    </section>

    <Footer></Footer>

  </div>
</template>
<script>
import axios from 'axios'
import Nav from "@/views/components/Nav";
import Footer from "@/views/components/Footer";
import { required, email } from 'vuelidate/lib/validators'
export default {
  components: {Nav, Footer},
  data() {
    return {
      form_success: true,
      form_loading: false,
      form: {
        first_name: null,
        last_name: null,
        email: null,
        message: null
      }
    }
  },
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      email:{ required, email },
      message: { required },
    }
  },
  methods: {
    encode (data) {
      return Object.keys(data)
          .map(
              key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
          )
          .join("&");
    },
    submit () {
      this.$v.$touch()

      const axiosConfig = {
        header: { "Content-Type": "application/x-www-form-urlencoded" }
      };

      this.form_loading = true;
      axios.post(
          "/",
          this.encode({
            "form-name": "support-form",
            ...this.form
          }),
          axiosConfig
      ).then(() => {
        this.form_success = true
        this.form_loading = false

      }).catch(() => {
        this.form_success = false
        this.form_loading = false;

      })
    }
  }
}
</script>
